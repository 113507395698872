import React, { useEffect, useState } from "react";
import Layout from "../../Layout";
import "../../../styles/portfolio.scss";
import bawainsta from "../../../images/bawainsta.jpg";
import { gsap } from "gsap";

export default function EclipseMattressPortfolio() {
  var colors = [
    "#f38630",
    "#6fb936",
    "#ccc",
    "#6fb936",
    "#55CDD7",
    "#25262E",
    "#5A028D",
    "#603A38",
  ];

  const pics = [
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/eclipse/june1.jpg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/eclipse/june2.jpg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/eclipse/june3.jpg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/eclipse/june4.jpg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/eclipse/june5.jpg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/eclipse/june6.jpg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/eclipse/june7.jpg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/eclipse/june8.jpg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/eclipse/july1.jpg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/eclipse/july2.jpg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/eclipse/july3.jpg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/eclipse/july4.jpg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/eclipse/july5.jpg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/eclipse/july6.jpg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/eclipse/july7.jpg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/eclipse/july8.jpg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/eclipse/july9.jpg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/eclipse/july10.jpg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/eclipse/july11.jpg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/eclipse/july12.jpg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/eclipse/july13.jpg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/eclipse/july14.jpg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/eclipse/july15.jpg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/eclipse/july16.jpg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/eclipse/july17.jpg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/eclipse/july18.jpg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/eclipse/july19.jpg",
    },
    {
      img: "https://blackelephantdigital.com/assets/portfolio/eclipse/aug1.jpg",
    },
    {
      img: "https://blackelephantdigital.com/assets/portfolio/eclipse/aug2.jpg",
    },
    {
      img: "https://blackelephantdigital.com/assets/portfolio/eclipse/aug3.jpg",
    },
    {
      img: "https://blackelephantdigital.com/assets/portfolio/eclipse/aug4.jpg",
    },
    {
      img: "https://blackelephantdigital.com/assets/portfolio/eclipse/aug5.jpg",
    },
    {
      img: "https://blackelephantdigital.com/assets/portfolio/eclipse/aug6.jpg",
    },
    {
      img: "https://blackelephantdigital.com/assets/portfolio/eclipse/aug7.jpg",
    },
    {
      img: "https://blackelephantdigital.com/assets/portfolio/eclipse/aug8.jpg",
    },
    {
      img: "https://blackelephantdigital.com/assets/portfolio/eclipse/aug9.jpg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/eclipse/aug10.jpg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/eclipse/aug11.jpg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/eclipse/aug12.jpg",
    },
    {
      img: "https://blackelephantdigital.com/assets/portfolio/eclipse/oct1.jpg",
    },
    {
      img: "https://blackelephantdigital.com/assets/portfolio/eclipse/oct2.jpg",
    },
    {
      img: "https://blackelephantdigital.com/assets/portfolio/eclipse/oct3.jpg",
    },
    {
      img: "https://blackelephantdigital.com/assets/portfolio/eclipse/oct4.jpg",
    },
    {
      img: "https://blackelephantdigital.com/assets/portfolio/eclipse/oct5.jpg",
    },
    {
      img: "https://blackelephantdigital.com/assets/portfolio/eclipse/oct6.jpg",
    },
    {
      img: "https://blackelephantdigital.com/assets/portfolio/eclipse/oct7.jpg",
    },
    {
      img: "https://blackelephantdigital.com/assets/portfolio/eclipse/oct8.jpg",
    },
    {
      img: "https://blackelephantdigital.com/assets/portfolio/eclipse/oct9.jpg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/eclipse/oct10.jpg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/eclipse/oct11.jpg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/eclipse/oct12.jpg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/eclipse/oct13.jpg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/eclipse/oct14.jpg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/eclipse/oct15.jpg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/eclipse/oct16.jpg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/eclipse/oct17.jpg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/eclipse/oct18.jpg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/eclipse/oct19.jpg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/eclipse/oct20.jpg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/eclipse/oct21.jpg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/eclipse/oct22.jpg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/eclipse/oct23.jpg",
    },
    {
      img:
        "https://blackelephantdigital.com/assets/portfolio/eclipse/oct24.jpg",
    },
    {
      img: "https://blackelephantdigital.com/assets/portfolio/eclipse/nov1.jpg",
    },
    {
      img: "https://blackelephantdigital.com/assets/portfolio/eclipse/nov2.jpg",
    },
    {
      img: "https://blackelephantdigital.com/assets/portfolio/eclipse/nov3.jpg",
    },
    {
      img: "https://blackelephantdigital.com/assets/portfolio/eclipse/nov4.jpg",
    },
    {
      img: "https://blackelephantdigital.com/assets/portfolio/eclipse/nov5.jpg",
    },
    {
      img: "https://blackelephantdigital.com/assets/portfolio/eclipse/nov6.jpg",
    },
    {
      img: "https://blackelephantdigital.com/assets/portfolio/eclipse/nov7.jpg",
    },
    {
      img: "https://blackelephantdigital.com/assets/portfolio/eclipse/nov8.jpg",
    },
  ];

  let col1 = [],
    col2 = [],
    col3 = [];

  for (let i = 0; i < pics.length; i++) {
    if ((i + 1) % 3 === 0) {
      col1.push(pics[i]);
    } else if ((i + 1) % 2 === 0) {
      col2.push(pics[i]);
    } else {
      col3.push(pics[i]);
    }
  }

  console.log(col1);
  console.log(col2);
  console.log(col3);
  const [updateComponent, setUpdateComponent] = useState(0);

  function handleClick() {}

  useEffect(() => {
    var w = window.innerWidth;

    let animboxes = gsap.set(".box", {
      backgroundColor: (i) => colors[Math.floor(Math.random() * 8)],
      y: (i) => (i * w) / 4,
    });

    let anim1 = gsap.to(".wrapper1 .box", {
      duration: 49,
      ease: "none",
      y: "+=" + (w / 4) * 10, //move each box 500px to right
      modifiers: {
        y: gsap.utils.unitize(
          (y) => parseFloat(y) % ((w / 4) * col1.length + 1)
        ), //force x value to be between 0 and 500 using modulus
      },
      repeat: -1,
    });
    let anim2 = gsap.to(".wrapper2 .box", {
      duration: 51,
      ease: "none",
      y: "-=" + (w / 4) * 10, //move each box 500px to right
      modifiers: {
        y: gsap.utils.unitize(
          (y) => parseFloat(y) % ((w / 4) * col2.length + 1)
        ), //force x value to be between 0 and 500 using modulus
      },
      repeat: -1,
    });
    let anim3 = gsap.to(".wrapper3 .box", {
      duration: 48,
      ease: "none",
      y: "+=" + (w / 4) * 10, //move each box 500px to right
      modifiers: {
        y: gsap.utils.unitize(
          (y) => parseFloat(y) % ((w / 4) * col3.length + 1)
        ), //force x value to be between 0 and 500 using modulus
      },
      repeat: -1,
    });

    window.addEventListener("resize", () => {
      anim1.kill();
      anim2.kill();
      anim3.kill();

      animboxes.kill();

      const boxes = document.querySelectorAll(".box");

      boxes.forEach((item) => {
        item.style = "";
      });
      setUpdateComponent(updateComponent + 1);
      w = window.innerWidth;
      animboxes = gsap.set(".box", {
        backgroundColor: (i) => colors[(i % colors.length) + 1],
        y: (i) => (i * w) / 4,
      });

      anim1 = gsap.to(".wrapper1 .box", {
        duration: 49,
        ease: "none",
        y: "+=" + (w / 4) * 10, //move each box 500px to right
        modifiers: {
          y: gsap.utils.unitize(
            (y) => parseFloat(y) % ((w / 4) * col1.length + 1)
          ), //force x value to be between 0 and 500 using modulus
        },
        repeat: -1,
      });
      anim2 = gsap.to(".wrapper2 .box", {
        duration: 51,
        ease: "none",
        y: "-=" + (w / 4) * 10, //move each box 500px to right
        modifiers: {
          y: gsap.utils.unitize(
            (y) => parseFloat(y) % ((w / 4) * col1.length + 1)
          ), //force x value to be between 0 and 500 using modulus
        },
        repeat: -1,
      });
      anim3 = gsap.to(".wrapper3 .box", {
        duration: 48,
        ease: "none",
        y: "+=" + (w / 4) * 10, //move each box 500px to right
        modifiers: {
          y: gsap.utils.unitize(
            (y) => parseFloat(y) % ((w / 4) * col3.length + 1)
          ), //force x value to be between 0 and 500 using modulus
        },
        repeat: -1,
      });
    });
  }, []);

  return (
    <Layout>
      <div className="portfolio">
        <div
          style={{ backgroundColor: "#082D51" }}
          className="portfolio__container"
        >
          <div className="portfolio__client">
            <div
              className="portfolio__client__logo"
              style={{ backgroundColor: "#082D51" }}
            >
              <img
                className=""
                src="https://eclipsemattress.in/static/logo-white-daba48091508e8c9cb459f31394fe409.png"
              ></img>
              {/* <div>Jaycee Power</div> */}
            </div>
          </div>

          <div className="portfolio__wrappers">
            <div class="wrapper wrapper1">
              <div class="boxes">
                {col1.map((item, i) => (
                  <div className="box" key={`col1-${i}`}>
                    <img src={item.img}></img>
                  </div>
                ))}
              </div>
            </div>
            <div class="wrapper wrapper2">
              <div class="boxes">
                {col2.map((item, i) => (
                  <div className="box" key={`col2-${i}`}>
                    <img src={item.img}></img>
                  </div>
                ))}
              </div>
            </div>
            <div class="wrapper wrapper3">
              <div class="boxes">
                {col3.map((item, i) => (
                  <div className="box" key={`col3-${i}`}>
                    <img src={item.img}></img>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
